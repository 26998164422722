body {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "N27";
  src: url("./fonts/n27-extralight-webfont.woff") format("woff"),
    url("./fonts/n27-extralight-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: lighter;
}

@font-face {
  font-family: "N27";
  src: url("./fonts/n27-regular-webfont.woff") format("woff"),
    url("./fonts/n27-regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "N27";
  src: url("./fonts/n27-medium-webfont.woff") format("woff"),
    url("./fonts/n27-medium-webfont.woff2") format("woff2");
  font-weight: 500;
  font-style: bold;
}

/* @font-face {
  font-family: "N27";
  src: url("./fonts/n27-bold-webfont.woff") format("woff"),
    url("./fonts/n27-bold-webfont.woff2") format("woff2");
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: "N27";
  src: url("./fonts/n27-bold-webfont.woff") format("woff"),
    url("./fonts/n27-bold-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "N27";
  src: url("./fonts/n27-bold-webfont.woff") format("woff"),
    url("./fonts/n27-bold-webfont.woff2") format("woff2");
  font-weight: 800;
  font-style: bold;
} */
* {
  font-family: "N27" !important;
  text-decoration: none !important;
  /* outline: 1px solid limegreen !important; */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
