.trainerCard img {
  width: 185px;
  height: 185px;
  border-radius: 50%;
}
.trainerCard {
  margin-bottom: 30px;
}
hr {
  color: #fff;
  opacity: 0.2;
}

.trainerCard p {
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  opacity: 1;
  transition: 0.2s ease-in-out;
}
/* @media screen and (max-width: 1800px) {
  .trainerCard p {
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
  }
} */
@media screen and (max-width: 1400px) {
  .trainerCard img {
    width: 170px;
    height: 170px;
  }
  .trainerCard p {
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    margin: 0;
  }
}
@media screen and (max-width: 1200px) {
  .trainerCard img {
    width: 140px;
    height: 140px;
  }
  .trainerCard p {
    color: #fff;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    margin: 0;
  }
}
@media screen and (max-width: 1000px) {
  .trainerCard img {
    width: 100px;
    height: 100px;
  }
  .trainerCard p {
    color: #fff;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    margin: 0;
  }
}
@media screen and (max-width: 900px) {
  .trainerCard img {
    width: 185px;
    height: 185px;
  }
  .trainerCard p {
    color: #fff;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    margin: 0;
  }
}
@media screen and (max-width: 430px) {
  .trainerCard img {
    width: 190px;
    height: 190px;
  }
}

@media screen and (max-width: 395px) {
  .trainerCard p {
    color: #fff;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 400;
    margin: 0;
  }

  .trainerCard img {
    width: 175px;
    height: 175px;
  }
}
