.homeTitle {
  font-size: 48px;
  line-height: 57px;
  color: #fff;
  font-weight: 400;
}

.homeBanner {
  background-image: url("../assets/images/Start_Desktop.jpg");
  background-size: cover;
  background-position: center center;
  height: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

.homeBanner p {
  color: #f5aac9;
  line-height: 76.8px;
  font-size: 64px;
  font-weight: 500;
}

.subText {
  font-size: 24px;
  color: #fff;
}

/* ASFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDFDAFADSFDASFADSFDSAFDSFDA */
.homeBannerMobile {
  position: relative;
}

.homeBannerMobile p {
  position: absolute;
  color: #f5aac9;
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1950px) {
  .homeParagraph {
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    font-weight: 300;
    margin: 0px;
  }

  .homeBanner {
    height: 600px;
  }

  .homeBanner p {
    color: #f5aac9;
    line-height: 57px;
    font-size: 48px;
    font-weight: 500;
  }

  .textHeaderPink {
    color: #f5aac9;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }
}

.homeParagraph {
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  font-weight: 300;
}

@media screen and (max-width: 1950px) {
  .homeTitle {
    font-size: 36px;
    line-height: 42px;
    color: #fff;
  }
}
@media screen and (max-width: 1400px) {
  .homeBanner {
    height: 500px;
  }

  .subText {
    font-size: 20px;
  }

  .homeBanner p {
    color: #f5aac9;
    line-height: 45px;
    font-size: 40px;
    font-weight: 500;
  }
  .homeTitle {
    font-size: 24px;
    line-height: 28px;

    color: #fff;
  }
}
@media screen and (max-width: 1200px) {
}
