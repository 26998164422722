.linkStylesFooter p {
  color: #fff;

  font-size: 26px;
  font-weight: 300;
  line-height: 43px;
  margin: 0;
  opacity: 1;
  transition: 0.2s ease-in-out;
}

/* .linkStylesFooter p:hover {
  color: #f5aac9 !important;
  transition: all 0.2s ease-in-out !important;
} */

.infoStylesFooter p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.footerLogo {
  width: 113px;
  height: 142px;
}

@media screen and (max-width: 1800px) {
  .linkStylesFooter p {
    color: #fff;

    font-size: 19.5px;
    font-weight: 300;
    line-height: 32.25px;
  }

  .footerLogo {
    width: 85px;
    height: 106px;
  }
}

@media screen and (max-width: 1800px) {
  .infoStylesFooter p {
    color: #fff;
    font-size: 13.5px;
    font-weight: 300;
    line-height: 16.5px;
    margin: 0;
  }
} ;
